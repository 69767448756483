import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Image } from './Images'
import { SearchBox } from './SearchBox/SearchBox'
import { SearchContext } from '../contexts/searchContext'
import { useLocation } from '@reach/router'

type styleType = {
  [key: string]: string
}

interface BannerHomepageProps {
  src?: string
  videoSrc?: { mp4: string; poster: string }
  rootStyle?: styleType
  innerStyle?: styleType
  className?: string
  locationList?: any
  distinctPropertyTypes?: any
}

const useStyles = (rootStyle: styleType, innerStyle: styleType) =>
  makeStyles((theme) => ({
    root: {
      position: 'relative',
      width: '100%',
      height: 'calc(100vw * (618/1400))', // Maintain aspect ratio of 1400:618
      maxHeight: '100dvh', // Limit maximum height to viewport height// Plein écran vertical
      backgroundColor: '#f4f4f4',
      ...rootStyle,
      [theme.breakpoints.down('sm')]: {
        height: '60vh',
      },
    },
    imageContainer: {
      width: '100%',
      height: 'calc(100vw * (618/1400))', // Maintain aspect ratio of 1400:618
      maxHeight: '100dvh', // Limit maximum height to viewport height
      objectFit: 'cover',
      objectPosition: 'center',
       [theme.breakpoints.down('sm')]: {
       
        height: '60vh',
      },
    },
    searchBoxWrapper: {
      position: 'absolute',
      top: '50%',
      right: '-10%',
      transform: 'translate(-50%, -50%)', // Centrage exact
      zIndex: 10,
      width: '90%',
      maxWidth: '500px',
      padding: '20px',
      [theme.breakpoints.down('sm')]: {
        bottom: '0%',
        left: '50%',
      },
    },
    inner: {
      ...innerStyle,
      zIndex: 2,
      position: 'relative',
    },
  }))

export const BannerHomepage: React.FC<BannerHomepageProps> = (props) => {
  const { src, videoSrc, rootStyle, children, innerStyle, className = '', locationList, distinctPropertyTypes } = props
  const classes = useStyles(rootStyle || {}, innerStyle || {})()

  const { setSearch, flushSearchState, searchState } = useContext(SearchContext)

  const location = useLocation();


  useEffect(() => {
    if (location?.hash === "#no-update") {

    } else {
      flushSearchState()
      setSearch({ name: 'transactionType', value: 'buy' })
    }


  }, [location?.hash])

  return (
    <Grid container justifyContent="center" className={classes.root}>
      {/* Image ou Vidéo */}
      {!!src && (
        <Image
          src={src}
          layout="CONSTRAINED"
          className={`${classes.imageContainer} ${className}`}
          alt="BannerHomepage"
          imgStyle={{ objectPosition: 'center' }}
        />
      )}
      {!!videoSrc && (
        <video
          poster={videoSrc.poster}
          autoPlay
          loop
          muted
          playsInline
          className={classes.imageContainer}
        >
          <source src={videoSrc.mp4} type="video/mp4" />
        </video>
      )}

      <div className={classes.searchBoxWrapper}>
        <SearchBox locationList={locationList} searchState={searchState} setSearch={setSearch} distinctPropertyTypes={distinctPropertyTypes} />
      </div>

      {/* Contenu Additionnel */}
      <Grid item className={classes.inner}>
        {children}
      </Grid>
    </Grid>
  )
}
